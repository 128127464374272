import { useCart, useUser } from '@unified-commerce/gpc-vue-storefront-shopify';

import analytics from '~/helpers/analytics';
import { clear, save } from '~/helpers/localStorage';

export const CART_LOCAL_STORAGE_KEY = 'cart';

const useLocalStorageCart = () => {
  const { cart } = useCart();
  const { user } = useUser();
  const setCartLocalStorage = () => {
    save(CART_LOCAL_STORAGE_KEY, cart.value);
    analytics({
      event: 'cart_update',
      cart: cart.value,
      customer_id: user?.value?.id,
    });
  };

  const clearCartLocalStorage = () => {
    clear(CART_LOCAL_STORAGE_KEY);
    analytics({
      event: 'cart_update',
      cart: null,
      customer_id: user?.value?.id,
    });
  };

  return {
    setCartLocalStorage,
    clearCartLocalStorage,
  };
};

export default useLocalStorageCart;
