import { computed, ComputedRef } from '@nuxtjs/composition-api';
import { Product, productGetters } from '@unified-commerce/gpc-vue-storefront-shopify';
import { isValid, parseISO } from 'date-fns';

import { ProductGalleryImage } from '~/components/@types/IProduct';
import { ProductViewModel } from '~/components/@types/ProductViewModel';

import useUiHelpers from '../useUiHelpers';

const BRAND = 'Sparesbox';

const parsePromoEndDate = (endDate: string, productTitle: string): Date | undefined => {
  const parsedEndDate = parseISO(endDate);
  if (isValid(parsedEndDate)) {
    return parsedEndDate;
  }
  console.warn(`Product ${productTitle} promotion end date ${endDate} is not a valid date format.`);
};

const getGalleryWithPlaceholder = (product: any): ProductGalleryImage[] => {
  if (product && product.images.length === 0) {
    product.images.push({
      originalSrc:
        'https://cdn.shopify.com/s/files/1/0407/1902/4288/files/placeholder_600x600.jpg?v=1625742127',
    });
  }

  return productGetters.getGallery(product).map((media) => {
    return {
      mobile: { url: media.small },
      desktop: { url: media.normal },
      big: { url: media.big },
      alt: product?.name || '',
      video: media.video
        ? {
            url: media.video.url || '',
            mimeType: media.video.mimeType || '',
            external: media.video.external || false,
            host: media.video.host || '',
          }
        : null,
    };
  });
};

// eslint-disable-next-line max-lines-per-function
export const toProductViewModel = (product: ComputedRef<Product>): ProductViewModel => {
  const title = computed(() => productGetters.getName(product.value));

  const variantImageIndex = computed(() => {
    if (!product.value) {
      return 0;
    }
    const variantBySelectedOptions = productGetters.getVariantBySelectedOptions(product.value) || {
      image: { originalSrc: undefined },
    };
    const hasVariantSelected = productGetters.hasVariantSelected(product.value);
    const variantImageSrc = hasVariantSelected
      ? variantBySelectedOptions.image?.originalSrc || ''
      : '';
    const response = productGetters.getVariantImageIndex(product.value, variantImageSrc);
    return response;
  });

  return {
    id: computed(() => productGetters.getId(product.value)),
    title,
    description: computed(() => productGetters.getDescription(product.value)),
    descriptionHtml: computed(() => productGetters.getDescription(product.value, true)),
    handle: computed(() => productGetters.getSlug(product.value)),
    partNumber: computed(
      () => productGetters.getProductVariantMetafield(product.value, 'partNumber') ?? undefined,
    ),
    price: computed(() => productGetters.getProductPrice(product.value).price ?? 0),
    compareToPrice: computed(
      () => productGetters.getProductPrice(product.value).compareToPrice ?? undefined,
    ),
    isAvailableForSale: computed(() => productGetters.getSaleStatus(product.value)),
    sku: computed(() => productGetters.getSKU(product.value)),
    asku: computed(() => productGetters.getProductVariantMetafield(product.value, 'asku')),
    stock: computed(() => productGetters.getStock(product.value)),
    stockQty: computed(() => {
      return product.value ? productGetters.getStock(product.value, { supportFreeSale: true }) : 0;
    }),
    availability: computed(() =>
      productGetters.getProductAvailability(product.value, { supportFreeSale: true }),
    ),
    vendor: computed(() => productGetters.getVendorName(product.value)),
    breadcrumbs: computed(() =>
      productGetters.getProductCategoryBreadcrumbs(product.value, BRAND, {
        text: 'All Parts',
        link: '/parts',
      }),
    ),
    productAttributes: computed(() => {
      return {
        title: 'Product attributes',
        contentType: 'attribute-list',
        content: productGetters.getProductAttribute(product.value),
      };
    }),
    productSpecifications: computed(() => ({
      title: 'Specifications',
      contentType: 'attribute-list',
      content: productGetters.getProductSpecification(product.value) ?? [],
    })),
    detailsAndCare: computed(() => ({
      title: 'Details & Care',
      contentType: 'html',
      content: productGetters.getProductVariantMetafield(product.value, 'detail_care'),
    })),
    weight: computed(() => productGetters.getWeight(product.value) ?? 0),
    productGallery: computed(() => getGalleryWithPlaceholder(product.value)),
    handlingTime: computed(
      () => productGetters.getProductVariantMetafield(product.value, 'handlingTime') || '3',
    ),
    promotionEndDate: computed(() =>
      parsePromoEndDate(productGetters.getPromotionEndDate(product.value), title.value),
    ),
    hasFitment: computed(
      () => productGetters.getProductVariantMetafield(product.value, 'hasFitment') === 'true',
    ),
    promotionHandle: computed(() => {
      const handle = productGetters.getProductVariantMetafield(
        product.value,
        'productPromoCollection',
      );
      if (!handle || typeof handle !== 'string') {
        return null;
      }

      return handle.replace(
        `${useUiHelpers().SEARCH_CATEGORY_TENANT_PREFIX_HANDLE}promotions-`,
        '/promotions/',
      );
    }),
    promotionImage: computed(() => {
      return productGetters.getProductMetafield(product.value, 'productPromoImage');
    }),
    tags: computed(() =>
      productGetters
        .getTags(product.value)
        .filter((tag) => tag.toLowerCase() !== 'sales_promo_active'),
    ),
    isDeal: computed(() => productGetters.isDeal(product.value)),
    isSpecialOrder: computed(
      () => productGetters.getProductMetafield(product.value, 'specialOrderRequired') === 'true',
    ),
    hasDealExpiry: computed(() => productGetters.hasDealExpiry(product.value)),
    dealExpiryDate: computed(() => productGetters.getDealExpiryDate(product.value)),
    collections: computed(() => productGetters.getCollections(product.value)),
    bundles: computed(() => productGetters.getBundledProductsInformation(product.value)),
    isBundle: computed(() => productGetters.isBundle(product.value)),
    variantImageIndex,
    variantId: computed(() => productGetters.getVariantId(product.value)),
    variantBySelectedOptions: computed(
      () => productGetters.getVariantBySelectedOptions(product.value) ?? null,
    ),
    hasVariantSelected: computed(() => productGetters.hasVariantSelected(product.value)),
  };
};
