import { computed, ComputedRef } from '@nuxtjs/composition-api';
import { Product, productGetters, useProduct } from '@unified-commerce/gpc-vue-storefront-shopify';

import { ProductViewModel } from '~/components/@types/ProductViewModel';

import { toProductViewModel } from './toProductViewModel';

interface Error {
  name: string;
  message: string;
  stack?: string;
}

interface UseShopifyProduct {
  search: (params: any) => Promise<void>;
  loading: ComputedRef<boolean>;
  error: ComputedRef<Error | undefined>;
  product: ProductViewModel;
  tempRawProduct: ComputedRef<Product>;
}

const useShopifyProduct = (): UseShopifyProduct => {
  const { products, loading, error, search } = useProduct('products');
  const product = computed(() => productGetters.getFiltered(products.value)[0]);

  return {
    search,
    loading: computed(() => loading.value),
    error: computed(() => error.value?.search),
    product: toProductViewModel(product),
    tempRawProduct: product,
  };
};

export default useShopifyProduct;
