import { computed, ref } from '@nuxtjs/composition-api';

const isScrollLocked = ref(false);

const useScrollLock = () => {
  const scrollLockClasses = computed(() => {
    const baseClass = 'default-layout';
    const scrollLockClass = 'scroll-lock';

    return [
      baseClass,
      {
        [scrollLockClass]: isScrollLocked.value,
      },
    ];
  });

  const toggleScrollLock = () => {
    isScrollLocked.value = !isScrollLocked.value;
  };

  return {
    isScrollLocked,
    scrollLockClasses,
    toggleScrollLock,
  };
};

export default useScrollLock;
