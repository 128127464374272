<template>
  <ProductPage
    :product-fits-vehicle="productFitsVehicle"
    :is-universal-product="isUniversalProduct"
    :fitment-search-data="fitmentSearchData"
    :cart-loading="cartLoading"
    @add-to-cart="addingToCart"
  />
</template>

<script>
import { computed, onBeforeUnmount, ref, useAsync, watch } from '@nuxtjs/composition-api';
import { searchGetters } from '@unified-commerce/gpc-vue-storefront-search-io';
import {
  cartGetters,
  productGetters,
  useCart,
  useProduct,
  useUser,
} from '@unified-commerce/gpc-vue-storefront-shopify';
import trim from 'lodash/trim';

import ProductPage from '~/components/Pages/ProductPage/ProductPage.vue';
import { useMyGarage, useRecentlyViewedProducts, useUiHelpers, useUiState } from '~/composables';
import useFitmentData from '~/composables/useFitmentData';
import useLocalStorageCart from '~/composables/useLocalStorageCart';
import useSbCategories from '~/composables/useSbCategories';
import useShopifyProduct from '~/composables/useShopifyProduct';
import useUiNotification from '~/composables/useUiNotification';
import { getProductFailedToAddCartNotification } from '~/composables/useUiNotification/commonNotifications';
import analytics from '~/helpers/analytics';

export const NOTIFICATION_KEY_PRODUCT_ADDED = 'product_added';

export default {
  name: 'ProductPageRoute',
  components: {
    ProductPage,
  },
  beforeRouteEnter(to, from, next) {
    const { resetVehiclesFromGarage } = useMyGarage();
    const { setPreviouslySelectedVehicle, userSelectedVehicle } = useUiState();
    const vehicleSlugRegex = new RegExp('/vehicles/.+$', 'ig');

    // if user comes from the vehicle specific page to PDP, disable the selected vehicle if applicable
    if (userSelectedVehicle.value && vehicleSlugRegex.test(from.path)) {
      setPreviouslySelectedVehicle(userSelectedVehicle.value);
      resetVehiclesFromGarage();
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    // While leaving the page, reset the garage state if previously selected vehicle is present
    const { addVehicle } = useMyGarage();
    const { previouslySelectedVehicle, setPreviouslySelectedVehicle } = useUiState();
    if (previouslySelectedVehicle.value) {
      addVehicle(previouslySelectedVehicle.value);
      setPreviouslySelectedVehicle(null);
    }
    next();
  },
  transition: 'fade',
  // eslint-disable-next-line max-lines-per-function
  setup(_props, context) {
    const { slug } = context.root.$route.params;
    const { userSelectedVehicle, setRecentlyAddedProduct } = useUiState();
    const { send: sendNotification } = useUiNotification();
    const { setCurrentRouteAsNotFound, setCurrentRouteAsInternalError } = useUiHelpers();
    const optionsNotSelected = ref([]);

    const { user, loading: userLoading } = useUser();
    const {
      product,
      error: productError,
      tempRawProduct: tempRawProduct,
      search,
    } = useShopifyProduct();

    const { getSbCategoryNameFromText } = useSbCategories();

    const { products: recommendedProducts, search: searchRecommendedProducts } =
      useProduct('recommendedProducts');

    const { addItem, loading: cartLoading, error: cartError } = useCart();
    const { setCartLocalStorage } = useLocalStorageCart();

    const { pushRecentlyViewedProduct } = useRecentlyViewedProducts();
    onBeforeUnmount(() => {
      pushRecentlyViewedProduct(tempRawProduct.value);
    });

    const productFitsVehicle = computed(() =>
      vehicleAskus.value?.askus?.includes(product.asku.value),
    );

    const productGallery = computed(() => {
      if (tempRawProduct.value && tempRawProduct.value.images.length === 0) {
        tempRawProduct.value.images.push({
          originalSrc:
            'https://cdn.shopify.com/s/files/1/0407/1902/4288/files/placeholder_600x600.jpg?v=1625742127',
        });
      }

      return productGetters.getGallery(tempRawProduct.value).map((media) => ({
        mobile: { url: media.small },
        desktop: { url: media.normal },
        big: { url: media.big },
        alt: tempRawProduct.value?.name || '',
        video: media.video,
      }));
    });

    // fitment data injections
    const { getProductsFitmentData, productsFitmentResult, vehicleAskus } = useFitmentData();

    const isUniversalProduct = computed(() => {
      return (
        Array.isArray(productsFitmentResult?.value?.results) &&
        productsFitmentResult.value.results?.some((result) => result?.record?.universal === '1')
      );
    });

    const fitmentSearchData = computed(() => searchGetters.getItems(productsFitmentResult.value));

    // not using watch causes fitment data not to be injected reactively
    watch(product.asku, async () => {
      await getProductsFitmentData([product.asku.value]);
    });

    // end fitment data injection
    const productCategoriesBreadcrumbs = computed(() =>
      productGetters.getProductCategoryBreadcrumbs(tempRawProduct.value, 'Sparesbox', {
        text: 'All Parts',
        link: '/parts',
      }),
    );

    const productCategory = computed(() => {
      const productCategoryLink =
        productCategoriesBreadcrumbs?.value[productCategoriesBreadcrumbs.value.length - 1]?.text;

      return getSbCategoryNameFromText(productCategoryLink);
    });

    const shouldSendViewItemEvent = computed(() => {
      return tempRawProduct.value && productCategory.value && !userLoading.value;
    });

    watch([productCategory, user], () => {
      if (shouldSendViewItemEvent.value) {
        analytics({
          event: 'view_item',
          product: tempRawProduct.value,
          discount: tempRawProduct.compareToPrice?.value,
          price: tempRawProduct.price?.value,
          category: productCategory?.value,
          customer_id: user?.value?.id,
        });
      }
    });

    useAsync(async () => {
      await search({ slug });

      const vehicleSelectedCookie = await useMyGarage().getVehicleSelectionFromCookie();

      if (!tempRawProduct.value) {
        setCurrentRouteAsNotFound({
          errorMessage: 'This product could not be found',
          data: [tempRawProduct, vehicleAskus, userSelectedVehicle, vehicleSelectedCookie],
        });
      } else if (productError.value) {
        setCurrentRouteAsInternalError({
          data: {
            error: productError.value,
            data: tempRawProduct.value,
          },
        });
      } else {
        await Promise.allSettled([
          searchRecommendedProducts({ productId: product.id.value, recommended: true }),
        ]);
      }
    }, slug);

    const addingToCart = async (quantity) => {
      await addItem({
        product: tempRawProduct.value,
        quantity,
      });

      analytics({
        event: 'add_to_cart',
        id: tempRawProduct?.value?.id,
        name: tempRawProduct?.value?.name,
        price: tempRawProduct.price?.value,
        vendor: tempRawProduct?.value?.vendor,
        category: productCategory?.value,
        customer_id: user?.value?.id,
      });

      setCartLocalStorage();

      if (cartError.value.addItem) {
        sendNotification(
          getProductFailedToAddCartNotification({
            name: tempRawProduct.value.name,
          }),
        );
      } else {
        setRecentlyAddedProduct({
          variantId: product.variantId.value,
          name: tempRawProduct.value.name,
          brand: tempRawProduct.value.vendor,
          price: tempRawProduct.value.price.original || tempRawProduct.value.price.current,
          specialPrice: tempRawProduct.value.price.original
            ? tempRawProduct.value.price.current
            : null,
          sku: product.sku.value,
          partNumber: product.partNumber.value,
          img: productGallery.value[0]?.mobile?.url || '',
        });
      }
    };

    watch(userSelectedVehicle, async (userSelectedVehicle) => {
      if (userSelectedVehicle?.KtypNr) {
        await getProductsFitmentData([product.asku.value]);
      }
    });

    return {
      productFitsVehicle,
      isUniversalProduct,
      fitmentSearchData,
      cartLoading,
      cartGetters,
      recommendedProducts,
      optionsNotSelected,
      addingToCart,
      sendNotification,
      addItem,
    };
  },
  head() {
    const { path } = this.$route;

    if (this.product) {
      const structuredData = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        '@id': `https://${this.$config.BASE_URL}${trim(path)}`,
        name: this.product.title,
        image: this.productGallery.map((image) => image.desktop.url),
        description: this.product.description,
        keywords: (this.product.tags || []).join(', '),
        sku: productGetters.getSKU(this.product),
        brand: {
          '@type': 'Brand',
          name: this.product.vendor || '',
        },
        offers: {
          '@type': 'Offer',
          url: `https://${this.$config.BASE_URL}${trim(path)}`,
          priceCurrency: 'AUD',
          price: this.price ? this.price.toString() : '',
          itemCondition: 'https://schema.org/NewCondition',
          availability:
            this.stockQty > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
        },
      };

      if (productGetters.isDeal(this.product)) {
        structuredData.offers.sale_price = this.price ? this.price.toString() : '';
        structuredData.offers.price = this.compareToPrice ? this.compareToPrice.toString() : '';
        structuredData.offers.priceValidUntil = productGetters.getDealExpiryDate(this.product);
      }

      return {
        title: this.product.title,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.product.description,
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: `https://${this.$config.BASE_URL}${trim(path)}`,
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: this.product.title,
          },
          {
            hid: 'og:type',
            property: 'og:type',
            content: 'product',
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: this.product.description,
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: this.productGallery[0].desktop.url,
          },
          {
            hid: 'og:image:secure_url',
            property: 'og:image:secure_url',
            content: this.productGallery[0].desktop.url,
          },
          {
            hid: 'og:price:amount',
            property: 'og:price:amount',
            content: this.price ? this.price.toString() : '',
          },
          {
            hid: 'og:price:currency',
            property: 'og:price:currency',
            content: 'AUD',
          },
          {
            hid: 'twitter:title',
            name: 'twitter:title',
            content: this.product.title,
          },
          {
            hid: 'twitter:description',
            name: 'twitter:description',
            content: this.product.description,
          },
          {
            hid: 'twitter:image',
            name: 'twitter:image',
            content: this.productGallery[0].desktop.url
              ? this.productGallery[0].desktop.url
              : '~/static/icons/sparesbox-app-icon-512x512.png',
          },
          {
            hid: 'twitter:card',
            name: 'twitter:card',
            content: this.productGallery[0].desktop.url ? 'summary_large_image' : 'summary',
          },
        ],
        script: [
          {
            type: 'application/ld+json',
            json: structuredData,
          },
        ],
      };
    }

    return {};
  },
};
</script>
