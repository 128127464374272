import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7c116dcc = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _5b634848 = () => interopDefault(import('../pages/account/AccountDetails.vue' /* webpackChunkName: "pages/account/AccountDetails" */))
const _32450b7f = () => interopDefault(import('../pages/account/Addresses.vue' /* webpackChunkName: "pages/account/Addresses" */))
const _f2a39540 = () => interopDefault(import('../pages/account/LogoutModal.vue' /* webpackChunkName: "pages/account/LogoutModal" */))
const _3d936355 = () => interopDefault(import('../pages/account/ManageMarketingEmails.vue' /* webpackChunkName: "pages/account/ManageMarketingEmails" */))
const _210d2ee2 = () => interopDefault(import('../pages/account/MyOrdersReturnsApp.vue' /* webpackChunkName: "pages/account/MyOrdersReturnsApp" */))
const _345dd806 = () => interopDefault(import('../pages/account/MyVehicles.vue' /* webpackChunkName: "pages/account/MyVehicles" */))
const _53731ece = () => interopDefault(import('../pages/account/OrderHistory.vue' /* webpackChunkName: "pages/account/OrderHistory" */))
const _a649802c = () => interopDefault(import('../pages/account/TrackMyOrder.vue' /* webpackChunkName: "pages/account/TrackMyOrder" */))
const _49a3e88e = () => interopDefault(import('../pages/ActivateAccount.vue' /* webpackChunkName: "pages/ActivateAccount" */))
const _292725fc = () => interopDefault(import('../pages/ambassador-listing/index.vue' /* webpackChunkName: "pages/ambassador-listing/index" */))
const _3419cac0 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _867d232c = () => interopDefault(import('../pages/buying-guides/index.vue' /* webpackChunkName: "pages/buying-guides/index" */))
const _266a2bd0 = () => interopDefault(import('../pages/media-landing/index.vue' /* webpackChunkName: "" */))
const _07148da2 = () => interopDefault(import('../pages/ForgotPassword.vue' /* webpackChunkName: "pages/ForgotPassword" */))
const _3f7336aa = () => interopDefault(import('../pages/Healthcheck.vue' /* webpackChunkName: "pages/Healthcheck" */))
const _af19ea70 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _121ec7fd = () => interopDefault(import('../pages/media-listing/index.vue' /* webpackChunkName: "pages/media-listing/index" */))
const _72524daa = () => interopDefault(import('../pages/my-vehicles/index.vue' /* webpackChunkName: "pages/my-vehicles/index" */))
const _3635de02 = () => interopDefault(import('../pages/parts/index.vue' /* webpackChunkName: "pages/parts/index" */))
const _ab2e8a72 = () => interopDefault(import('../pages/project-cars/index.vue' /* webpackChunkName: "pages/project-cars/index" */))
const _4fd0e8c9 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "pages/ResetPassword" */))
const _b33f304e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _9b6f896e = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _176f288a = () => interopDefault(import('../pages/vehicles/index.vue' /* webpackChunkName: "pages/vehicles/index" */))
const _0203e590 = () => interopDefault(import('../pages/article/_slug.vue' /* webpackChunkName: "" */))
const _e7279ed6 = () => interopDefault(import('../pages/video/_slug.vue' /* webpackChunkName: "" */))
const _2dece9bd = () => interopDefault(import('../pages/ambassador/_slug.vue' /* webpackChunkName: "" */))
const _32632978 = () => interopDefault(import('../pages/brands/_slug.vue' /* webpackChunkName: "pages/brands/_slug" */))
const _89ea65bc = () => interopDefault(import('../pages/buying-guides/_slug.vue' /* webpackChunkName: "pages/buying-guides/_slug" */))
const _fd1576c4 = () => interopDefault(import('../pages/pages/_slug.vue' /* webpackChunkName: "pages/pages/_slug" */))
const _347f3cba = () => interopDefault(import('../pages/parts/_slug.vue' /* webpackChunkName: "pages/parts/_slug" */))
const _683f3660 = () => interopDefault(import('../pages/products/_slug.vue' /* webpackChunkName: "pages/products/_slug" */))
const _ae9bcd02 = () => interopDefault(import('../pages/project-cars/_slug.vue' /* webpackChunkName: "pages/project-cars/_slug" */))
const _3771da5c = () => interopDefault(import('../pages/promotions/_slug.vue' /* webpackChunkName: "pages/promotions/_slug" */))
const _54d05462 = () => interopDefault(import('../pages/shop/_slug.vue' /* webpackChunkName: "pages/shop/_slug" */))
const _2c398c36 = () => interopDefault(import('../pages/vehicles/_make.vue' /* webpackChunkName: "pages/vehicles/_make" */))
const _4269309e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _7c116dcc,
    name: "account___en",
    children: [{
      path: "AccountDetails",
      component: _5b634848,
      name: "account-AccountDetails___en"
    }, {
      path: "Addresses",
      component: _32450b7f,
      name: "account-Addresses___en"
    }, {
      path: "LogoutModal",
      component: _f2a39540,
      name: "account-LogoutModal___en"
    }, {
      path: "ManageMarketingEmails",
      component: _3d936355,
      name: "account-ManageMarketingEmails___en"
    }, {
      path: "MyOrdersReturnsApp",
      component: _210d2ee2,
      name: "account-MyOrdersReturnsApp___en"
    }, {
      path: "MyVehicles",
      component: _345dd806,
      name: "account-MyVehicles___en"
    }, {
      path: "OrderHistory",
      component: _53731ece,
      name: "account-OrderHistory___en"
    }, {
      path: "TrackMyOrder",
      component: _a649802c,
      name: "account-TrackMyOrder___en"
    }]
  }, {
    path: "/ActivateAccount",
    component: _49a3e88e,
    name: "ActivateAccount___en"
  }, {
    path: "/ambassador-listing",
    component: _292725fc,
    name: "ambassador-listing___en"
  }, {
    path: "/brands",
    component: _3419cac0,
    name: "brands___en"
  }, {
    path: "/buying-guides",
    component: _867d232c,
    name: "buying-guides___en"
  }, {
    path: "/content-hub",
    component: _266a2bd0,
    name: "content-hub___en"
  }, {
    path: "/ForgotPassword",
    component: _07148da2,
    name: "ForgotPassword___en"
  }, {
    path: "/Healthcheck",
    component: _3f7336aa,
    name: "Healthcheck___en"
  }, {
    path: "/login",
    component: _af19ea70,
    name: "login___en"
  }, {
    path: "/media-landing",
    component: _266a2bd0,
    name: "media-landing___en"
  }, {
    path: "/media-listing",
    component: _121ec7fd,
    name: "media-listing___en"
  }, {
    path: "/my-vehicles",
    component: _72524daa,
    name: "my-vehicles___en"
  }, {
    path: "/parts",
    component: _3635de02,
    name: "parts___en"
  }, {
    path: "/project-cars",
    component: _ab2e8a72,
    name: "project-cars___en"
  }, {
    path: "/ResetPassword",
    component: _4fd0e8c9,
    name: "ResetPassword___en"
  }, {
    path: "/search",
    component: _b33f304e,
    name: "search___en"
  }, {
    path: "/signup",
    component: _9b6f896e,
    name: "signup___en"
  }, {
    path: "/vehicles",
    component: _176f288a,
    name: "vehicles___en"
  }, {
    path: "/content-hub/ambassador-picks",
    component: _292725fc,
    name: "ambassador-listing___en"
  }, {
    path: "/content-hub/articles-and-videos",
    component: _121ec7fd,
    name: "media-listing___en"
  }, {
    path: "/content-hub/articles-and-videos/articles/:slug",
    component: _0203e590,
    name: "article___en"
  }, {
    path: "/content-hub/articles-and-videos/videos/:slug",
    component: _e7279ed6,
    name: "video___en"
  }, {
    path: "/content-hub/ambassador-picks/:slug",
    component: _2dece9bd,
    name: "ambassador___en"
  }, {
    path: "/ambassador/:slug?",
    component: _2dece9bd,
    name: "ambassador-slug___en"
  }, {
    path: "/article/:slug?",
    component: _0203e590,
    name: "article-slug___en"
  }, {
    path: "/brands/:slug",
    component: _32632978,
    name: "brands-slug___en"
  }, {
    path: "/buying-guides/:slug?",
    component: _89ea65bc,
    name: "buying-guides-slug___en"
  }, {
    path: "/pages/:slug?",
    component: _fd1576c4,
    name: "pages-slug___en"
  }, {
    path: "/parts/:slug",
    component: _347f3cba,
    name: "parts-slug___en"
  }, {
    path: "/products/:slug?",
    component: _683f3660,
    name: "products-slug___en"
  }, {
    path: "/project-cars/:slug?",
    component: _ae9bcd02,
    name: "project-cars-slug___en"
  }, {
    path: "/promotions/:slug?",
    component: _3771da5c,
    name: "promotions-slug___en"
  }, {
    path: "/shop/:slug?",
    component: _54d05462,
    name: "shop-slug___en"
  }, {
    path: "/vehicles/:make",
    component: _2c398c36,
    name: "vehicles-make___en"
  }, {
    path: "/video/:slug?",
    component: _e7279ed6,
    name: "video-slug___en"
  }, {
    path: "/activate-account/:customerId/:activationToken",
    component: _49a3e88e,
    name: "activate-customer-account___en"
  }, {
    path: "/reset-password/:customerId/:resetToken",
    component: _4fd0e8c9,
    name: "reset-customer-password___en"
  }, {
    path: "/vehicles/:make/:model?/:year?/:series?",
    component: _2c398c36,
    name: "vehicles-route___en"
  }, {
    path: "/brands/:slug/:slug2?/:slug3?/:slug4?/:slug5?",
    component: _32632978,
    name: "brand-pages___en"
  }, {
    path: "/parts/:slug/:slug2?/:slug3?/:slug4?/:slug5?/:slug6?",
    component: _347f3cba,
    name: "parts-pages___en"
  }, {
    path: "/",
    component: _4269309e,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
