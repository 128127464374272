import { ref } from '@nuxtjs/composition-api';
import {
  getFitmentAskus,
  getFitmentByKtypeParams,
  useSearch,
} from '@unified-commerce/gpc-vue-storefront-search-io';

import getFitmentSearchParams from '~/helpers/search/getFitmentSearchParams';

import useUiState from '../useUiState';

const showFittingParts = ref(true);
const vehicleAskus = ref<{ askus?: string[]; kTypeNr?: string } | null>(null);

const toggleShowFittingParts = () => {
  showFittingParts.value = !showFittingParts.value;
};

const useFitmentData = () => {
  const { search: searchAskusFromKtype, result: vehicleFitmentResult } = useSearch('askus-fitment');

  const { search: searchProductsFitment, result: productsFitmentResult } =
    useSearch('products-fitment');

  const { userSelectedVehicle } = useUiState();

  const getVehicleFitmentData = async () => {
    if (!userSelectedVehicle.value) {
      vehicleAskus.value = null;
      return;
    }
    if (userSelectedVehicle?.value?.KtypNr !== vehicleAskus?.value?.kTypeNr) {
      await searchAskusFromKtype(getFitmentByKtypeParams(userSelectedVehicle.value.KtypNr));
      const askus = getFitmentAskus(vehicleFitmentResult.value);
      if (askus && userSelectedVehicle.value?.KtypNr) {
        vehicleAskus.value = { askus, kTypeNr: userSelectedVehicle.value.KtypNr };
      }
    }
  };

  const getProductsFitmentData = async (searchResultsAskus: string[]) => {
    if (userSelectedVehicle.value && searchResultsAskus?.length > 0) {
      await searchProductsFitment(
        getFitmentSearchParams(searchResultsAskus, userSelectedVehicle.value.KtypNr),
      );
    }
  };

  return {
    getVehicleFitmentData,
    getProductsFitmentData,
    toggleShowFittingParts,
    vehicleFitmentResult,
    productsFitmentResult,
    vehicleAskus,
    showFittingParts,
  };
};

export default useFitmentData;
