import { computed, useContext } from '@nuxtjs/composition-api';

const WEBSITE_BANNERS_PATH = 'website-banner';

import {
  getStoriesAsArray,
  getStoryBlokVersion,
  useContent,
} from '@unified-commerce/gpc-vue-storefront-storyblok';

const useWebsiteBanner = () => {
  const { $config } = useContext();
  const { search: websiteBannerSearch, content: websiteBannerContent } =
    useContent(WEBSITE_BANNERS_PATH);

  const fetchWebsiteBanner = () => {
    websiteBannerSearch({
      url: WEBSITE_BANNERS_PATH,
      version: getStoryBlokVersion($config.ENV),
      custom: {
        starts_with: WEBSITE_BANNERS_PATH,
        filter_query: {
          component: {
            in: 'WebsiteBanner',
          },
        },
        sort_by: 'published_at:desc',
      },
      optimisticStoryLoading: true,
    });
  };

  const websiteBannerSlides = computed(() => getStoriesAsArray(websiteBannerContent.value));

  const hasWebsiteBanner = computed(() => !!websiteBannerSlides?.value?.length);

  return {
    fetchWebsiteBanner,
    websiteBannerSlides,
    hasWebsiteBanner,
  };
};

export default useWebsiteBanner;
