import { render, staticRenderFns } from "./Selection.vue?vue&type=template&id=30bb7d64&scoped=true&"
import script from "./Selection.vue?vue&type=script&lang=ts&"
export * from "./Selection.vue?vue&type=script&lang=ts&"
import style0 from "./Selection.vue?vue&type=style&index=0&id=30bb7d64&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30bb7d64",
  null
  
)

export default component.exports